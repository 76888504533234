export enum ENavPos {
    "top" = "top",
    "bottom" = "bottom",
    "left" = "left",
    "right" = "right",
}
export enum EToolbar {
    "top" = "top",
    "bottom" = "bottom",
}
export enum EFooter {
    "above" = "above",
    "below" = "below",
}
export enum EViewAnimation {
    "fadeLeft" = "fade-left",
    "fadeRight" = "fade-right",
    "fadeTop" = "fade-top",
    "fadeTopInOut" = "fade-top-in-out",
    "fadeBottom" = "fade-bottom",
    "fadeBottomInOut" = "fade-bottom-in-out",
    "fade" = "fade",
}

export enum EmployeeLocaleName {
    EmployeeEnglishName = "employeeEnglishName",
    EmployeeKoreanName = "employeeKoreanName",
}

export enum ApprovalActionButtons {
    SAVE = "SAVE",
    SUBMIT = "SUBMIT",
    CANCEL = "CANCEL",
    APPROVAL_STATUS = "APPROVAL_STATUS",
    CHANGE_STEP = "CHANGE_STEP",
    EDIT = "EDIT",
    APPROVAL = "APPROVAL",
    REDRAFT = "REDRAFT",
    PRINT = "PRINT",
}

export enum APPROVAL_MODULE_CODE {
    HOME = "HOME",
    SYSTEM = "SYSTEM",
}

export enum SSO_LOGIN_TYPE {
    HOS = "sso/hos",
    HMM = "sso/hmm",
}

export enum COMPANY_TYPE_CODE {
    HOS = "HOS",
}
